// @flow
import React from 'react';
import type { Node } from 'react';
import { Switch } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import Authentication from '@pages/Public/Authentication';
import MaterialErrorBoundary from '@components/template/MaterialErrorBoundary';
import NoMatch from '@pages/Public/NoMatch';
import { ROOT } from '@constants/urls';
import Route from '@utils/PublicRoute';

const PrivateRoutes = (): Node => {
  // const { t } = useTranslation(['seo']);
  return (
    <>
      <MaterialErrorBoundary>
        <Switch>
          <Route path={ROOT} component={Authentication} />
          <Route>
            <NoMatch />
          </Route>
        </Switch>
      </MaterialErrorBoundary>
    </>
  );
};

export default PrivateRoutes;
