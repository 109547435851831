// @flow
import React, { Suspense } from 'react';
import type { Node } from 'react';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';
import { useAsyncResource } from 'use-async-resource';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import { Offline as OfflineIntegration } from '@sentry/integrations';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Application from '@pages';
import theme from '@config/material';
import { FRONTEND_VERSION } from '@constants/api';
import { connectToServer } from '@config/serverConnect';
import { SENTRY_DSN, SENTRY_ENV } from '@constants/sentry';
import Preloader from '@components/template/Preloader';
import Layout from '@components/template/Layout';
import ErrorBoundary from '@components/template/ErrorBoundary';
import '@config/i18n';
import '@utils/typy';

import './App.css';

const ENV = process.env.NODE_ENV;
const history = createBrowserHistory();

if (['production', 'stagging'].includes(ENV) && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [
      new DedupeIntegration(),
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        traceFetch: false,
      }),
      new OfflineIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracingOrigins: [
      'api.inesa.com.mx',
      'app.inesa.com.mx',
      '127.0.0.1',
      'localhost', /^\//],
    tracesSampleRate: 0.3,
    debug: false,
    release: `inesa-frontend@${FRONTEND_VERSION}`,
    autoSessionTracking: true,
    beforeBreadcrumb: excludeGraphQLFetch,
    // beforeSend(event, hint) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   console.log({ event, hint });
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id });
    //   }
    //   return null;
    // },
  });
}

/* TODO: Get user role and load the according route */

const App = (): Node => {
  const [signatureReader, reCall] = useAsyncResource(connectToServer, []);
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    if (notistackRef.current) notistackRef.current.closeSnackbar(key);
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={'Cargando...'}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Preloader />}>
            <CssBaseline />
            <SnackbarProvider
              ref={notistackRef}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              maxSnack={3}
              action={(key) => (
                <IconButton onClick={onClickDismiss(key)} aria-label='close'>
                  <CloseIcon fontSize='small' />
                </IconButton>
              )}
            >
              <Layout>
                <Router history={history}>
                  <Application signatureReader={signatureReader} recallSignature={reCall} />
                </Router>
              </Layout>
            </SnackbarProvider>
          </Suspense>
        </ThemeProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
