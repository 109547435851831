export const NO_AUTH = 'not_authenticated';
export const ALREADY_AUTH = 'already_authenticated';
export const NOT_STAFF = 'not_staff';
export const NOT_SUPER = 'not_superuser';
export const NOT_ENOUGH_PERMS = 'not_enough_permissions';
export const REPORTED = 'reported';

export const i18nErrorPrefix = 'snacks:error.';

export const KNOWN_ERRORS = [
  NO_AUTH,
  ALREADY_AUTH,
  NOT_STAFF,
  NOT_SUPER,
  NOT_ENOUGH_PERMS,
  REPORTED,
];

export const COMMON_ERRORS = [
  NO_AUTH,
  ALREADY_AUTH,
];

export const IGNORE_ERRORS = [
];
