// @flow
import React from 'react';
import type { Node } from 'react';
// import * as Sentry from '@sentry/react';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';
// import { t as typy } from 'typy';
import * as Yup from 'yup';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typo from '@mui/material/Typography';

import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';

import AuthContext from '@contexts/Authentication';
import FeedbackButton from '@components/atoms/FeedbackButton';
// import { DASH_SETUP } from '@constants/urls';

type LoginType = {
  // openRegister: () => void,
  openRestore: () => void,
  close: () => void,
};

const Login = ({ openRestore }: LoginType): Node => {
  const { t } = useTranslation(['login', 'inputs', 'common']);
  const { login, loading, saving } = React.useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState(false);
  // const history = useHistory();

  const minPasswordChars = 8;

  const onSubmit = async (values) => {
    login(values);
  };

  // React.useEffect(() => {
  //   if (openAuthentication && user) {
  //     const step = typy(user, 'registrationStep').safeString;
  //     if (step && !['GET_PAID', 'ADD_PRODUCTS', 'STORE_SETUP', 'DONE'].includes(step)) {
  //       history.push(DASH_SETUP);
  //     }
  //     close();
  //   }
  // }, [openAuthentication, user, history, close]);

  return (
    <Stack px={3} py={3}>
      <Typo variant='h1' mb={3} mx={6}>{t('login:title')}</Typo>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t('inputs:errors.valid.email'))
            .required(t('inputs:errors.required.email')),
          password: Yup.string()
            .required(t('inputs:errors.required.password'))
            .min(
              minPasswordChars,
              t('inputs:errors.min.password', { count: minPasswordChars }),
            ),
        })}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, isValid, isValidating }) => (
          <Grid container component='form' onSubmit={handleSubmit} rowSpacing={3} mb={3} justifyContent='center'>
            <Grid item xs={12} md={10}>
              <Field
                name='email'
                variant='filled'
                type='email'
                label={t('inputs:labels.email')}
                component={TextField}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={10}>
              <Field
                name='password'
                variant='filled'
                type={showPassword ? 'text' : 'password'}
                label={t('inputs:labels.password')}
                component={TextField}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      onClick={() => setShowPassword(!showPassword)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <VisibilityIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={10}>
              <FeedbackButton
                type='submit'
                disabled={!isValid || isValidating || isSubmitting || loading}
                loading={saving}
                variant='contained'
                // color='success'
                fullWidth
              >
                {t('buttons:log_in')}
              </FeedbackButton>
            </Grid>

            <Grid item xs={12} md={10} display='flex' justifyContent='center'>
              <Button
                onClick={() => {
                  openRestore();
                  // console.log('showReportDialog');
                  // Sentry.showReportDialog();
                }}
                variant='text'
              >
                {t('buttons:forgot_password')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>

    </Stack>
  );
};

export default Login;
