// @flow
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import LocalStorageBackend from 'i18next-localstorage-backend';
import namespaces from '@config/namespaces.json';
import LANGUAGES from '@constants/languages';

// const lng = 'en';
const fallbackLng = 'es';
const availableLanguages = LANGUAGES.filter((ln) => ln.i18n).map((ln) => ln.value);
const inDevelopment = process.env.NODE_ENV === 'development';

i18n
  // load translation using xhr -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    fallbackLng: inDevelopment ? undefined : fallbackLng,
    debug: inDevelopment,

    supportedLngs: availableLanguages,
    defaultNS: 'common',
    fallbackNS: 'common',
    ns: inDevelopment ? [
      'buttons',
      'common',
      'inputs',
    ] : namespaces,
    // ns: [],

    saveMissing: inDevelopment,
    saveMissingTo: 'all',
    load: 'currentOnly',

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/private/locales/{{lng}}/{{ns}}',
    },

    interpolation: {
      escapeValue: false,
      // nsSeparator: false,
      // keySeparator: false,

      format: (value, format, lng) => {
        /* if the format is 'currentDate', then take its __value__ transfom it to a moment object,
         * translate it to the current language and show it in Month DD, YYYY format. */
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (value instanceof Date) return moment(value).locale(lng).format(format);
        if (value instanceof moment && format === 'ago') return value.locale(lng).startOf('hour').fromNow(true);
        if (value instanceof moment) return value.locale(lng).format(format);
        return value;
      },
    },
  });

i18n.on('initialized', ({ lng }) => {
  moment.locale(lng);
});
i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
