// @flow
import React from 'react';
import type { Node } from 'react';
import useDimensions from 'react-cool-dimensions';
import MUIAvatar from '@mui/material/Avatar';
import { getURLFromResponsiveThumb } from '@utils/thumbnails';
import { PLACEHOLDER_IMAGE, PLACEHOLDER_IMAGE_THUMBS } from '@constants/assets';

import type { ThumbnailsNode } from '@types/constants';

type AvatarProps = {
  image?: ThumbnailsNode | null,
  alt: string,
  defaultImage?: string,
}

export const Avatar = ({ image, alt, ...props }: AvatarProps): Node => {
  const { observe, width } = useDimensions();
  const thumbnail = React.useMemo(() => (
    getURLFromResponsiveThumb(image || PLACEHOLDER_IMAGE_THUMBS, width) || PLACEHOLDER_IMAGE
  ), [image, width]);
  return (
    <MUIAvatar
      {...props}
      alt={alt}
      src={thumbnail}
      ref={observe}
    />
  );
};

export default Avatar;
