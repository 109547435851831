// @flow
import React from 'react';
import type { Node } from 'react';

const NoMatch = (): Node => {
  // const { t } = useTranslation(['seo']);
  return (
    <>
      No Match
    </>
  );
};

export default NoMatch;
