// @flow
import React from 'react';
import type { Node } from 'react';
import useDimensions from 'react-cool-dimensions';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';

const drawerWidth = 240;

type DashboardTemplateProps = {
  sidebar: Node,
  toolbar: Node,
  children: Node,
}

const DashboardTemplate = ({ toolbar, sidebar, children }: DashboardTemplateProps): Node => {
  const { observe, height } = useDimensions();
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        ref={observe}
        position='fixed'
        elevation={1}
        sx={{
          zIndex: 1300,
          bgcolor: '#fff',
        }}
      >
        {toolbar}
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        elevation={1}
        variant='permanent'
        anchor='left'
      >
        <Toolbar sx={{ height: `${height}px` }} />
        {sidebar}
      </Drawer>

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          bgcolor: 'background.main',
          // mt: `${height}px`,
          maxWidth: `calc(100vw - ${drawerWidth}px)`,
        }}
      >
        <Toolbar sx={{ height: `${height}px` }} />
        <Box
          sx={{
            maxHeight: `calc(100vh - ${height}px)`,
            minHeight: `calc(100vh - ${height}px)`,
            overflow: 'auto',
            p: 3,
          }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardTemplate;
