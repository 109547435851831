import { gql } from '@apollo/client';

export const LIST_SAMPLING_TYPES = gql`
  query listSamplingTypes {
    listSamplingTypes {
      __typename
      pageInfo {
        __typename
        hasNext
        hasPrev
        page
        pageSize
        totalPages
        totalObjects
      }
      objects {
        __typename
        id
        code
        title

        archived
        editionDate
        creationDate
      }
    }
  }
`;

export const OPTIONS_SAMPLING_TYPES = gql`
  query listSamplingTypes {
    listSamplingTypes {
      __typename
      objects {
        __typename
        id
        code
        title
      }
    }
  }
`;
