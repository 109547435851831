import React from 'react';

import Backdrop from '@mui/material/Backdrop';

import Spinner from '@components/atoms/Spinner';

// Inspired by the former Facebook spinners.
function Preloader(props) {
  return (
    <Backdrop
      sx={{ backgroundColor: 'backdrop.main', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <Spinner size={60} thickness={7} {...props} />
    </Backdrop>
  );
}

export default Preloader;
