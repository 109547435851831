// @flow
import React from 'react';
import type { Node } from 'react';
import * as Sentry from '@sentry/react';

const SentryErrorBoundary = ({ children }: { children: Node }): typeof Sentry.ErrorBoundary => {
  const [message, setMessage] = React.useState('');

  return (
    <Sentry.ErrorBoundary
      showDialog
      fallback={({ error, componentStack, resetError }) => (
        <React.Fragment>
          <div>You have encountered an error</div>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
          <button
            onClick={() => {
              setMessage('We recommend you to refresh your website.');
              resetError();
            }}
          >
            Click here to reset!
          </button>
        </React.Fragment>
      )}
    >
      <div>{message}</div>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
