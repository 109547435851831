import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(username: $email, password: $password) {
      user {
        id
        firstName
        lastName
        email

        thumbnails {
          key
          url
          width
          height
          __typename
        }

        roleName
        permissions
        dateJoined
        __typename
      }
    }
  }
`;
