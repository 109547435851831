// @flow
import React from 'react';
import type { Node } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';

import { LocalizationProvider } from '@mui/x-date-pickers';
import esLocale from 'date-fns/locale/es';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import AuthProvider from '@contexts/Authentication/Provider';
import { CSRF_TOKEN, CAN_MUTATE_TOKEN } from '@constants/api';
import Routes from '@pages/Routes';
import { generateClient } from '@config/apollo';

const Application = ({ signatureReader }): Node => {
  const { t } = useTranslation('snacks');
  const signature = signatureReader();
  const { enqueueSnackbar } = useSnackbar();

  const client = React.useMemo(() => {
    if (signature) {
      return generateClient(signature[CSRF_TOKEN], t, enqueueSnackbar);
    }
    return null;
  }, [signature, t, enqueueSnackbar]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
      <ApolloProvider client={client}>
        <AuthProvider canCallJWT={signature[CAN_MUTATE_TOKEN]}>
          <Routes />
        </AuthProvider>
      </ApolloProvider>
    </LocalizationProvider>
  );
};

export default Application;
