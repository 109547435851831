// @flow
import React from 'react';
import type { Node } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typo from '@mui/material/Typography';

import { ERROR_500 } from '@constants/assets';

const MUIErrorBoundary = ({ children }: { children: Node }): typeof Sentry.ErrorBoundary => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['buttons', 'snacks']);
  return (
    <Sentry.ErrorBoundary
      showDialog
      fallback={({ error, resetError }) => (
        <React.Fragment>
          <Stack
            alignItems='center'
            justifyContent='center'
            sx={{ minHeight: '100%' }}
          >
            <img
              src={ERROR_500}
              style={{
                maxWidth: '30rem',
                maxHeight: '100%',
              }}
              alt='Error 500'
            />
            <Typo mb={2}>{error.toString()}</Typo>
            <Button
              mt={3}
              onClick={() => {
                enqueueSnackbar(t('snacks:warning.refresh_recommended'), { variant: 'warning' });
                resetError();
              }}
            >
              {t('buttons:reset')}
            </Button>
          </Stack>
        </React.Fragment>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default MUIErrorBoundary;
