// @flow
import React from 'react';
import type { Node } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { t as typy } from 'typy';

import { LOGIN_MUTATION } from '@mutations/auth/login';
import { VERIFY_TOKEN_MUTATION } from '@mutations/auth/verifyToken';
import { REFRESH_TOKEN_MUTATION } from '@mutations/auth/refreshToken';
import { DELETE_TOKEN_MUTATION } from '@mutations/auth/deleteToken';
import { MY_INFO } from '@queries/user/myInfo';
import { GQLCatcher } from '@utils/sentry';

import { APP } from '@constants/urls';
// import { useGQLError } from '@hooks/useGQLError';
import AuthContext from './index';
import type { AuthenticationContextValue } from './index';

type AuthenticationProviderProps = { children: Node }

export const AuthenticationProvider = ({
  canCallJWT,
  children,
}: AuthenticationProviderProps): Node => {
  const { t } = useTranslation(['common']);
  // const onError = useGQLError();
  const [user, setUser] = React.useState();
  const [permissions, setPermissions] = React.useState([]);
  const [avatar, setAvatar] = React.useState();
  const [isLogged, setLogged] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [timerID, setTimerID] = React.useState();
  // const [openAuthentication, setOpenAuthModal] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  // Schedule the next refresh
  let scheduleTokenRefresh = (_: number) => {}; // eslint-disable-line no-unused-vars

  const setUserData = React.useCallback((userData) => {
    setUser(userData);
    setAvatar(typy(userData, 'thumbnails').safeObject);
    setPermissions(typy(userData, 'storePermissions').safeArray);
  }, []);

  const clearUserData = () => {
    setUser(null);
    setAvatar(null);
    setPermissions([]);
  };

  const clearData = () => {
    clearUserData();
    setLogged(false);
    setLoaded(true);
    clearTimeout(timerID);
    setTimerID(null);

    // client.cache.reset();
  };

  const redirectToPage = React.useCallback(() => {
    if (!location.state) {
      history.push(APP);
      return;
    }

    const { from: fromURL = null } = location.state;
    if (fromURL) history.replace(fromURL);
  }, [location, history]);

  const [getMyInfoQuery, { data: myInfoData, loading: loadingInfo }] = useLazyQuery(MY_INFO, {
    // fetchPolicy: 'network-only',
    onCompleted: (data) => {
      // if (isLogged && openAuthentication) setOpenAuthModal(false);
      setUserData(typy(data, 'myInfo').safeObject);
      setLogged(true);
      setLoaded(true);
      // verifyTokenMutation();
    },
    onError: () => {
      setLogged(false);
      setLoaded(true);
    },
  });

  // Verify the token and can schedule the refresh
  const [verifyTokenMutation] = useMutation(VERIFY_TOKEN_MUTATION, {
    onCompleted: (data) => {
      scheduleTokenRefresh(typy(data, 'verifyToken.payload.exp').safeNumber);
      if (!isLogged) getMyInfoQuery();
      else {
        setLogged(true);
        setLoaded(true);
      }
    },
    onError: (error) => {
      clearData();
      GQLCatcher(error);
      setLoaded(true);
      console.error('error :>>', error.message);
    },
  });

  // Refresh the current token and schedule the next refresh
  const [refreshTokenMutation, { loading: savingRefresh }] = useMutation(REFRESH_TOKEN_MUTATION, {
    onCompleted: (data) => {
      scheduleTokenRefresh(typy(data, 'refreshToken.payload.exp').safeNumber);
      setLogged(true);
    },
    onError: (error) => {
      clearData();
      // GQLCatcher(error);
      console.error('error :>>', error.message);
    },
  });

  const [loginMutation, { loading: savingLogin }] = useMutation(LOGIN_MUTATION, {
    // errorPolicy: 'all',
    onCompleted: (data) => {
      const tempUser = typy(data, 'tokenAuth.user').safeObject;
      setUserData(tempUser);
      setLogged(true);
      setLoaded(true);

      verifyTokenMutation().then(() => {
        redirectToPage();
        Sentry.setUser({
          id: typy(tempUser, 'id').safeString,
          email: typy(tempUser, 'email').safeString,
          ip_address: '{{auto}}',
        });
        enqueueSnackbar(t('snacks:success.login', { firstName: typy(tempUser, 'firstName').safeString }), { variant: 'info' });
      });
    },
    onError: () => {
      // enqueueSnackbar(error.message, { variant: 'error' });
      // GQLCatcher(error);
      setLogged(false);
      setLoaded(true);
    },
  });

  const [logoutMutation, { loading: savingLogout }] = useMutation(DELETE_TOKEN_MUTATION, {
    onCompleted: () => {
      enqueueSnackbar(t('snacks:success.logout'), { variant: 'info' });
      // validateAPI();
      clearData();
    },
    onError: () => {
      clearData();
      // enqueueSnackbar(error.message, { variant: 'error' });
      // GQLCatcher(error);
    },
  });

  scheduleTokenRefresh = (exp: number) => {
    const expiry = new Date(exp * 1000);
    const now = new Date();
    const remainingTime = expiry - now;
    clearTimeout(timerID);
    const timer = setTimeout(() => {
      refreshTokenMutation();
    }, remainingTime * 0.8);
    setTimerID(timer);
  };

  React.useEffect(() => {
    if (myInfoData) {
      setUserData(typy(myInfoData, 'myInfo').safeObject);
      setLogged(true);
      setLoaded(true);
    }
  }, [myInfoData, setUserData]);

  // Try to get the user info
  React.useEffect(() => {
    if (!loaded && canCallJWT) {
      verifyTokenMutation();
    }
  }, [loaded, canCallJWT, verifyTokenMutation]);

  const value: AuthenticationContextValue = {
    user,
    avatar,
    isLogged,
    loaded,
    loading: loadingInfo,
    saving: savingLogin || savingLogout || savingRefresh,
    // openAuthentication,
    refetchUser: getMyInfoQuery,
    login: (vars, onSuccess, onError) => {
      setLoaded(false);
      loginMutation({ variables: vars }).then(({ errors }) => {
        if (errors && onError) onError();
        else if (!errors && onSuccess) onSuccess();
      });
    },
    hasPerm: (perm: string) => {
      if (isLogged) return permissions.includes(perm);
      return false;
    },
    hasPermsAll: (perms: string[]) => {
      if (isLogged) {
        return perms.every(perm => permissions.includes(perm));
      }
      return false;
    },
    hasPermsAny: (perms: string[]) => {
      if (isLogged) {
        return perms.some(perm => permissions.includes(perm));
      }
      return false;
    },
    logout: (onSuccess, onError) => {
      logoutMutation().then(({ errors }) => {
        if (errors && onError) onError();
        else if (!errors && onSuccess) onSuccess();
      });
    },
    // toggleAuthenticationOpen: (force?: boolean) => {
    //   if (force === true || force === false) setOpenAuthModal(force);
    //   else setOpenAuthModal(!openAuthentication);
    // },
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthenticationProvider;
