// @flow
import React from 'react';
import type { Node } from 'react';
import * as Sentry from '@sentry/react';
import { Route, Redirect } from 'react-router-dom';

import AuthContext from '@contexts/Authentication';
import { LOGIN_PARAMS, ROOT } from '@constants/urls';

type ProtectedRouteProps = {|
  path: string | Array<string>,
  children: Node | null
|}

function ProtectedRoute({ children, ...rest }: ProtectedRouteProps): Node {
  const { loaded, isLogged } = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) => loaded
        ? !isLogged
          ? (
            <Redirect
              // push
              to={{
                pathname: ROOT,
                search: `?${LOGIN_PARAMS}`,
                state: { from: location },
              }}
            />
          )
          : (
            children
          )
        : null
      }
    />
  );
}

const SentryRoute: typeof Route = Sentry.withSentryRouting(ProtectedRoute);

export default SentryRoute;
