// @flow

export const WEB: string = 'https://www.inesa.com.mx';
export const WEBAPP: string = 'https://app.inesa.com.mx';

export const ROOT: string = '/';
export const APP: string = '/app';
export const FOO: string = '/coming-soon/';

// ===================================== Authentication ===========================================
export const LOGIN_PARAMS: string = 'login';
export const RESTORE_PARAMS: string = 'restore';
export const RESTORE_CONFIRM_PARAMS: string = 'restore_confirm';
export const VERIFY_EMAIL_PARAMS: string = 'confirm';

export const PROJECTS: string = `${APP}/proyects`;

export const QUOTES: string = `${APP}/quotes`;
export const ORDERS: string = `${APP}/orders`;
export const SAMPLES: string = `${APP}/samples`;
export const ANALYSIS: string = `${APP}/analysis`;
export const REPORTS: string = `${APP}/reports`;

export const USERS: string = `${APP}/users`;
