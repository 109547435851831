// @flow
import type { ThumbnailsNode } from '@types/constants';

export const ASSETS_CDN: string = process.env.NODE_ENV === 'production'
  ? '/assets/'
  : '/assets/';

export const IMAGES_CDN: string = `${ASSETS_CDN}/images/`;

export const PLACEHOLDER_IMAGE: string = `${IMAGES_CDN}placeholder.png`;
export const PLACEHOLDER_IMAGE_THUMBS: ThumbnailsNode = [
  { key: '1280x800', width: 1280, height: 800, url: `${IMAGES_CDN}placeholder.png` },
  { key: '800x533', width: 800, height: 533, url: `${IMAGES_CDN}placeholder-800x533.png` },
  { key: '600x400', width: 600, height: 400, url: `${IMAGES_CDN}placeholder-600x400.png` },
  { key: '450x300', width: 450, height: 300, url: `${IMAGES_CDN}placeholder-450x300.png` },
  { key: '300x200', width: 300, height: 200, url: `${IMAGES_CDN}placeholder-300x200.png` },
  { key: '225x150', width: 225, height: 150, url: `${IMAGES_CDN}placeholder-225x150.png` },
  { key: '150x100', width: 150, height: 100, url: `${IMAGES_CDN}placeholder-150x100.png` },
  { key: '75x50', width: 75, height: 50, url: `${IMAGES_CDN}placeholder-75x50.png` },
];
export const ERROR_500: string = `${IMAGES_CDN}/500.png`;
