import { gql } from '@apollo/client';

export const MY_INFO = gql`
  query myInfo {
    myInfo {
      id
      firstName
      lastName
      email

      thumbnails {
        key
        url
        width
        height
        __typename
      }

      roleName
      permissions
      dateJoined
      __typename
    }
  }
`;
