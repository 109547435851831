import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

// Inspired by the former Facebook spinners.
function Spinner(props) {
  return (
    <Box>
      <CircularProgress
        variant="indeterminate"
        sx={{
          // color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          color: 'backdrop.color',
          [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export default Spinner;
