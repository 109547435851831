// @flow
import React from 'react';
import type { Node } from 'react';
import { Switch } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import PublicRoutes from '@pages/Public/Routes';
import PrivateRoutes from '@pages/Private/Routes';
import ProtectedRoute from '@utils/ProtectedRoute';
import Route from '@utils/PublicRoute';
import { ROOT, APP } from '@constants/urls';

const ApplicationRoutes = (): Node => (
  <>
    <Switch>
      <ProtectedRoute path={APP}>
        <PrivateRoutes />
      </ProtectedRoute>

      <Route path={ROOT}>
        <PublicRoutes />
      </Route>
    </Switch>
  </>
);

export default ApplicationRoutes;
