// @flow
import React, { lazy } from 'react';
import type { Node } from 'react';
import { useQuery } from "@apollo/client";
import { useTranslation } from 'react-i18next';
import { Link, Switch } from 'react-router-dom';
import { t as typy } from 'typy';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
// import { useTranslation } from 'react-i18next';

import DashboardToolbar from '@components/template/Dashboard/DashboardToolbar';
import DashboardTemplate from '@components/template/Dashboard';
import MaterialErrorBoundary from '@components/template/MaterialErrorBoundary';
import {
  APP,
  ANALYSIS,
  // PROJECTS,
  ORDERS,
  QUOTES,
  REPORTS,
  SAMPLES,
} from '@constants/urls';
import AuthContext from '@contexts/Authentication';
import { LIST_SAMPLING_TYPES } from '@queries/samplingTypes';
import Route from '@utils/PublicRoute';
import componentLoader from '@utils/componentLoader';
import range from '@utils/range';
// import { useGQLError } from '@hooks/useGQLError';

import Welcome from '@pages/Private/Welcome';
const Analysis = lazy(() => componentLoader(() => import('@pages/Private/Analysis')));
const Quotes = lazy(() => componentLoader(() => import('@pages/Private/Quotes')));
const Orders = lazy(() => componentLoader(() => import('@pages/Private/Orders')));
// const Projects = lazy(() => componentLoader(() => import('@pages/Private/Projects')));
const Reports = lazy(() => componentLoader(() => import('@pages/Private/Reports')));
const Samples = lazy(() => componentLoader(() => import('@pages/Private/Samples')));

const PrivateRoutes = (): Node => {
  const { t } = useTranslation(['ui', 'buttons']);
  const { logout } = React.useContext(AuthContext);
  // const onError = useGQLError();
  // const { t } = useTranslation(['seo']);

  const { data: areasData, loading } = useQuery(LIST_SAMPLING_TYPES);

  const pages = React.useMemo(() => ([
    { text: t('ui:navbar.quotes'), url: QUOTES, disabled: true },
    { text: t('ui:navbar.orders'), url: ORDERS },
    { text: t('ui:navbar.samples'), url: SAMPLES },
    { text: t('ui:navbar.analyses'), url: ANALYSIS },
    { text: t('ui:navbar.reports'), url: REPORTS },
  ]), [t]);

  const settings = React.useMemo(() => ([
    { text: t('buttons:logout'), url: '#', onClick: () => logout() },
  ]), [t, logout]);

  const skeletonRange = range(0, 12);
  const areasRender = React.useMemo(() => {
    const dataArr = typy(areasData, 'listSamplingTypes.objects').safeArray;
    const types = [...dataArr]
      .sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      });
    return types
      .map(({ code, title, url = '#', icon, disabled = true }) => (
        <ListItem
          // {...props}
          sx={{ py: 0.5 }}
          disabled={disabled}
          button
          component={Link}
          to={url}
          key={code}
        >
          { icon
            ? <ListItemIcon>{icon}</ListItemIcon>
            : null
          }
          <ListItemText primary={title} primaryTypographyProps={{ variant: 'body2' }} />
        </ListItem>
      ));
  }, [areasData]);

  const toolbar = React.useMemo(() => (
    <DashboardToolbar
      pages={pages}
      settings={settings}
    />
  ), [pages, settings]);

  const sidebar = (
    <>
      <Divider />
      <List>
        { !loading
          ? areasRender
          : skeletonRange.map((val) => (
            <ListItem key={val}><ListItemText primaryTypographyProps={{ variant: 'body2' }}><Skeleton /></ListItemText></ListItem>
          ))
        }
      </List>
      <Divider />
    </>
  );

  return (
    <DashboardTemplate
      toolbar={toolbar}
      sidebar={sidebar}
    >
      <MaterialErrorBoundary>
        <Switch>
          <Route path={APP} exact component={Welcome} />

          <Route path={QUOTES} component={Quotes} />
          <Route path={ORDERS} component={Orders} />
          <Route path={SAMPLES} component={Samples} />
          <Route path={ANALYSIS} component={Analysis} />
          <Route path={REPORTS} component={Reports} />
          {/* <Route path={PROJECTS} component={Projects} /> */}
        </Switch>
      </MaterialErrorBoundary>
    </DashboardTemplate>
  );
};

export default PrivateRoutes;

// {  },
// { text: 'Microbiología', url: '', disabled: true, icon: undefined },
// { text: 'Alimentos', url: '', disabled: true, icon: undefined },
// { text: 'Fuentes Fijas', url: '', disabled: true, icon: undefined },
// { text: 'Ambiente Laboral', url: '', disabled: true, icon: undefined },
// { text: 'Residuos', url: '', disabled: true, icon: undefined },
