// @flow
import React from 'react';
import type { Node } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

type FeedbackButtonProps = {
  loading: boolean,
  disabled?: boolean,
  success?: boolean,
  fullWidth?: boolean,
  color?: string,
  children: Node,
}

const FeedbackButton = ({
  loading = false,
  disabled = false,
  // success = false,
  fullWidth = false,
  color,
  children,
  ...props
}: FeedbackButtonProps): Node => (
  <Box style={{ position: 'relative', width: fullWidth ? '100%' : undefined }}>
    <Button
      {...props}
      fullWidth={fullWidth}
      color={color}
      disabled={loading || disabled}
    >
      {children}
    </Button>
    {loading
      && <CircularProgress
        size={24}
        className='button-progress-cicle'
        color={color}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12,
        }}
      />}
  </Box>
);

export default FeedbackButton;
