// @flow

export const SENTRY_DSN: string | null = process.env.REACT_APP_SENTRY_DSN
  || 'https://0b905380310d4e4b90f8132aa0449ab6@o987510.ingest.sentry.io/6272557';
export const SENTRY_ENV: string | null = process.env.REACT_APP_SENTRY_ENVTAG
  || 'development';

// export const SENTRY_GQL_OPERATIONS: string[] = [
//   'DeleteTokenCookie',
//   // 'TokenAuth',
//   'RefreshToken',
//   'RestorePasswordSend',
//   'RestorePasswordConfirm',
//   'CreateUser',
//   'CreateUserResendConfirmation',
//   'VerifyEmail',
//   'VerifyToken',
// ];

export const IGNORE_OPERATIONS: string[] = [
  'TokenAuth',
];
