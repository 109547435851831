// @flow
import React from 'react';
import type { Node } from 'react';
// import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { t as typy } from 'typy';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinkMUI from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typo from '@mui/material/Typography';

import BugReportIcon from '@mui/icons-material/BugReport';
import MenuIcon from '@mui/icons-material/Menu';

import Avatar from '@components/atoms/Avatar';
import AuthContext from '@contexts/Authentication';
import { APP } from '@constants/urls';

export type ToolbarLinkProps = {
  url: string,
  text: string,
  disabled?: boolean,
}

export type SettingsLinkProps = {
  onClick?: () => void,
  icon?: Node,
} & ToolbarLinkProps;

type DashboardToolbarProps = {
  pages: ToolbarLinkProps[],
  settings: SettingsLinkProps[],
}

const DashboardToolbar = ({ pages, settings }: DashboardToolbarProps): Node => {
  const { t } = useTranslation('buttons');
  const { enqueueSnackbar } = useSnackbar();
  const { user, avatar } = React.useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Toolbar>
      <LinkMUI
        variant='h6' component={Link}
        to={APP}
        color='#e78767'
        noWrap
        sx={{ display: { xs: 'none', md: 'flex' }, letterSpacing: '2.6px', textDecoration: 'none' }}
      >
        INESA
      </LinkMUI>
      <Divider
        orientation='vertical' variant='middle'
        flexItem sx={{ mx: 4, display: { xs: 'none', md: 'flex' } }}
      />

      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleOpenNavMenu}
          color='inherit'
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          {pages.map(({ text, url, ...props }) => (
            <MenuItem
              {...props}
              key={text}
              component={Link} to={url}
              onClick={handleCloseNavMenu}
            >
              <Typo textAlign='center'>{text}</Typo>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Typo
        variant='h6' component='div'
        color='secondary'
        noWrap
        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, letterSpacing: '2.6px' }}
      >
        INESA
      </Typo>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {pages.map(({ text, url, ...props }) => (
          <Button
            {...props}
            key={text}
            component={Link} to={url}
            onClick={handleCloseNavMenu}
          >
            {text}
          </Button>
        ))}
      </Box>

      <Box mr={2}>
        <Tooltip title={t('buttons:report_bug')}>
          <IconButton
            onClick={() => {
              enqueueSnackbar(t('snacks:warning.coming_soon'), { variant: 'warning' });
              // Sentry.captureException(new Error('User Manual Feedback'), (scope) => {
              //   scope.setTransactionName("ManualFeedback");
              //   // scope.setTag("my-tag", "my value");
              //   scope.setLevel("warning");
              // });
            }}
          >
            <BugReportIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title={typy(user, 'firstName').safeString}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={typy(user, 'firstName').safeString} src={avatar} />
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map(({ text, url, icon, onClick, ...props }) => (
            <MenuItem
              {...props}
              component={Link} to={url} key={text}
              onClick={onClick || handleCloseUserMenu}
            >
              { icon
                ? (
                  <ListItemIcon>{icon}</ListItemIcon>
                )
                : null
              }
              <ListItemText>{text}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </Box>

    </Toolbar>
  );
};

export default DashboardToolbar;
