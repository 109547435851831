// FROM: https://gist.github.com/Botfather/4cfc9c7ba363c892acced7cc44d9f7ff#file-componentloader-js
// FROM: https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
export default function componentLoader(lazyComponent, attemptsLeft = 3, interval = 1000) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}
