// @flow
import React from 'react';
import type { Node } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import Typo from '@mui/material/Typography';

import { FRONTEND_VERSION } from '@constants/api';
import { IMAGES_CDN } from '@constants/assets';
import NewsCard from '@components/molecules/cards/NewsCard';

const Welcome = (): Node => {
  const { t } = useTranslation(['welcome', 'buttons']);
  /* eslint-disable max-len */
  const [upgradesList] = React.useState([
    { id: '1', image: `${IMAGES_CDN}placeholder.png`, version: '0.0.0', title: 'Sección de Reportes', summary: '', description: '' },
    { id: '2', image: `${IMAGES_CDN}placeholder.png`, version: '0.1.0', title: 'Base de datos de Clientes importada', summary: 'Esta es la primer versión de la base de datos, es decir solo se han importado algunas de las tablas que se encontraban...', description: 'dentro del antiguo sistema de INESA. Los datos importados hasta el momento son: Clientes, Datos legales de clientes, Sucursales y Contactos, pero próximamente se comenzará a integrar el restante de los datos.' },
    { id: '3', image: `${IMAGES_CDN}placeholder.png`, version: '0.2.0', title: 'Reportes Independientes', summary: '', description: '' },
    { id: '4', image: `${IMAGES_CDN}placeholder.png`, version: '0.3.0', title: 'Reporte para Impresión', summary: '', description: '' },
    { id: '5', image: `${IMAGES_CDN}placeholder.png`, version: '0.4.0', title: 'Reporte para Web', summary: '', description: '' },
    { id: '6', image: `${IMAGES_CDN}placeholder.png`, version: '0.4.1', title: 'Validadores de datos', summary: 'La información suministrada debe de ser validada, es decir, no es muy útil tener un cliente si no tiene su RFC dado de alta (para facturas y trazabilidad), por...', description: 'lo que el sistema actual validará ciertos datos, sobretodo los más importantes. Hasta el momento los validadores que se encuentran en operación son para RFC, CURP y Cuentas de Correo, pero próximamente se inclurán validadores para: Código Postal, Razón Social para empresas, números telefónicos, etc.' },
    { id: '7', image: `${IMAGES_CDN}placeholder.png`, version: '1.0.0', title: 'Arreglos en la carga de datos', summary: 'La creación de reportes presentó un error en la creación de clientes, direcciones y contactos. El problema ha sido resuelto.', description: '' },
    { id: '8', image: `${IMAGES_CDN}placeholder.png`, version: '1.1.0', title: 'Autocompletado de parametros', summary: '', description: '' },
  ].reverse());
  /* eslint-enable max-len */

  const upgradesListRender = React.useMemo(() => (
    upgradesList.map((upgrade) => (
      <NewsCard
        key={upgrade.id}
        {...upgrade}
      />
    ))
  ), [upgradesList]);

  return (
    <>
      <Typo component='h1' variant='h2' mb={3}>{t('welcome:title')}</Typo>
      <Typo component='h2' variant='h6' mb={6}>{t('welcome:version', { version: FRONTEND_VERSION })}</Typo>

      <Typo component='h2' variant='h5' mb={3}>{t('welcome:upgrades.title', { number: upgradesList.length })}</Typo>
      <Box>
        <Masonry columns={{ xs: 2, sm: 3, md: 4, lg: 4, xl: 3 }} spacing={{ xs: 2, md: 4 }}>
          {upgradesListRender}
        </Masonry>
      </Box>
    </>
  );
};

export default Welcome;
