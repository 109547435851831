// @flow
import React from 'react';
import type { Context } from 'react';

import type { UserNode } from '@types/user';
import type { ThumbnailsNode } from '@types/constants';

export type AuthenticationContextValue = {|
  user?: UserNode | null,
  refetchUser: () => Promise<*> | null,
  avatar?: ThumbnailsNode | null,
  isLogged: boolean,
  loaded: boolean,
  loading: boolean,
  saving: boolean,
  // openAuthentication: boolean,
  login: (vars: {}, onSuccess?: () => void, onError?: () => void) => void,
  hasPerm: (perm: string) => boolean,
  hasPermsAll: (perms: string[]) => boolean,
  hasPermsAny: (perms: string[]) => boolean,
  logout: (onSuccess?: () => void, onError?: () => void) => void,
  // toggleAuthenticationOpen: (force?: boolean) => void | null,
|};

const AuthenticationContext: Context<
  AuthenticationContextValue
> = React.createContext<
  AuthenticationContextValue
>({
  user: null,
  refetchUser: () => null,
  avatar: undefined,
  isLogged: false,
  loaded: false,
  loading: false,
  saving: false,
  // openAuthentication: false,
  login: () => {},
  hasPerm: () => false,
  hasPermsAll: () => false,
  hasPermsAny: () => false,
  logout: () => {},
  // toggleAuthenticationOpen: () => null,
});

export default AuthenticationContext;
