import {
  ApolloClient,
  from,
  InMemoryCache,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { SentryLink } from 'apollo-link-sentry';
// import * as Sentry from '@sentry/react';
import { API_BASE, FRONTEND_VERSION, CSRF_HEADER } from '@constants/api';
import { IGNORE_OPERATIONS } from '@constants/sentry';
import { i18nErrorPrefix, IGNORE_ERRORS, KNOWN_ERRORS } from '@config/errors';

const extraHeadersLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Client-Version': FRONTEND_VERSION,
    },
  };
});

const httpLink = createUploadLink({
  uri: API_BASE,
  credentials: 'include',
});

const errorSentryLink = new SentryLink({
  setTransaction: true,
  setFingerprint: true,
  shouldHandleOperation: (({ operationName }) => {
    // console.log({ operation });
    return !IGNORE_OPERATIONS.includes(operationName);
  }),
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeError: true,
    // transform: ((breadcrumb, operation) => {
    //   return breadcrumb;
    // }),
  },
});

export const generateClient = (csrftoken, t, enqueueSnackbar) => {
  const authLink = setContext((_, { headers }) => {
    // const token = localStorage.getItem(AUTH_TOKEN);
    return {
      credentials: 'include',
      headers: {
        ...headers,
        [CSRF_HEADER]: csrftoken,
        // authorization: token ? `Bearer ${token}` : ''
      },
    };
  });

  const gqlErrorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      console.error('⚛️ GraphQl Error ⚛️', { graphQLErrors });
      graphQLErrors.forEach(err => {
        if (KNOWN_ERRORS.includes(err.message)) enqueueSnackbar(t(`${i18nErrorPrefix}${err.message}`));
        else if (!IGNORE_ERRORS.includes(err.message)) enqueueSnackbar(err.message, { variant: 'error' });
      });
    }
    if (networkError) console.error('👮🏻‍♀️ network error', { networkError });
  });

  const additiveLink = from([
    authLink,
    extraHeadersLink,
    errorSentryLink,
    gqlErrorLink,
    httpLink,
  ]);

  const client = new ApolloClient({
    link: additiveLink,
    credentials: 'include',
    cache: new InMemoryCache(),
  });

  return client;
};
