import { gql } from '@apollo/client';

export const SIGNUP_MUTATION = gql`
  mutation CreateUser(
    $user: CreateUserInput!
  ) {
    createUser(
      user: $user
    ) {
      ok
    }
  }
`;

export const RESEND_CONFIRMATION = gql`
  mutation CreateUserResendConfirmation {
    createUserResendConfirmation {
      ok
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail(
    $uid: String!
    $code: String!
    $token: String!
  ) {
    verifyEmail(
      uid: $uid
      code: $code
      token: $token
    ) {
      ok
    }
  }
`;
