// @flow
export const API_BASE: string = process.env.REACT_APP_API_BASE
  || 'https://beta-api.inesa.com.mx/gql/';
export const API_REGISTER: string = process.env.REACT_APP_API_REGISTER
  || 'https://beta-api.inesa.com.mx/register/';

export const CSRF_TOKEN: string = 'csrftoken';
export const CONNECT_TOKEN: string = 'connected_token';
export const CAN_MUTATE_TOKEN: string = 'can_mutate';
export const REFRESH_TOKEN: string = 'refresh_token';
export const REBOOT_TOKEN: string = 'reboot_token';

export const CSRF_HEADER: string = 'X-CSRFToken';
export const FRONTEND_VERSION: string = process.env.GIT_TAG || '1.0.0';
