// @flow
import * as React from 'react';
import { styled } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typo from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import stringToColor from '@utils/colors';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
}));

const NewsCard = ({
  title,
  summary,
  description,
  image,
  version,
}: {
  id: string,
  title: string,
  description: string,
  image: string,
  version: string,
}): Node => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const major = version.split('.')[0];

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar aria-label="Major Version" sx={{ bgcolor: stringToColor(version) }}>
            {major}
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={title}
        subheader={version}
      />
      <CardMedia
        component="img"
        height="194"
        image={image}
        alt=""
      />
      <CardContent>
        <Typo variant="body2" color="text.secondary">
          {summary}
        </Typo>
      </CardContent>
      { !description
        ? null
        : (
          <>
            <CardActions disableSpacing>
              <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typo variant="body2" color="text.secondary">
                  {description}
                </Typo>
              </CardContent>
            </Collapse>
          </>
        )
      }
    </Card>
  );
};

export default NewsCard;
