// @flow
import { API_REGISTER, FRONTEND_VERSION } from '@constants/api';
import i18n from "i18next";

// should only be used once
export const connectToServer = () => fetch(API_REGISTER, {
  method: 'GET',
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Accept-Language': i18n.resolvedLanguage,
    'Client-Version': FRONTEND_VERSION,
    'Content-Type': 'application/json',
  },
}).then(res => res.json());
