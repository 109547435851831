// @flow
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const Layout = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.grey,
  minHeight: '100vh',
}));

export default Layout;
