// @flow
import React from 'react';
import type { Node } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

// import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typo from '@mui/material/Typography';

import Login from './Login';
// import Register from './components/Register';
// import RegisterSuccess from './components/RegisterSuccess';
// import Restore from './components/Restore';
// import RestoreConfirm from './components/RestoreConfirm';

import { VERIFY_EMAIL } from '@mutations/auth/signup';
import * as URLS from '@constants/urls';
import { IMAGES_CDN } from '@constants/assets';

const LOGIN_ZONE = URLS.LOGIN_PARAMS;
const RESTORE_ZONE = URLS.RESTORE_PARAMS;
const RESTORE_CONFIRM_ZONE = URLS.RESTORE_CONFIRM_PARAMS;

const imageLogin = `${IMAGES_CDN}login.jpg`;

export const Auth = (): Node => {
  const { t } = useTranslation(['login', 'snacks']);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();
  const [zone, setZone] = React.useState(LOGIN_ZONE);

  const [verifyEmail] = useMutation(VERIFY_EMAIL, {
    onCompleted: () => {
      const search = location.search;
      const queryParams = new URLSearchParams(search);
      queryParams.delete(URLS.VERIFY_EMAIL_PARAMS);
      queryParams.delete('uid');
      queryParams.delete('code');
      queryParams.delete('token');
      enqueueSnackbar(t('snacks:success.email_verified'), { preventDuplicate: true, variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { preventDuplicate: true, variant: 'error' });
    },
  });

  React.useEffect(() => {
    const search = location.search;
    if (search) {
      const queryParams = new URLSearchParams(search);
      if (queryParams.has(LOGIN_ZONE)) setZone(LOGIN_ZONE);
      else if (queryParams.has(RESTORE_ZONE)) setZone(RESTORE_ZONE);
      else if (queryParams.has(RESTORE_CONFIRM_ZONE)) setZone(RESTORE_CONFIRM_ZONE);
      else if (queryParams.has(URLS.VERIFY_EMAIL_PARAMS)) {
        // ?confirm=true&uid={uid}&code={instance.vcode}&token={token}
        verifyEmail({
          variables: {
            uid: queryParams.get('uid'),
            code: queryParams.get('code'),
            token: queryParams.get('token'),
          },
        });
      }
    }
  }, [location.search, enqueueSnackbar, t, verifyEmail]);

  const closeCallback = React.useCallback(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has(LOGIN_ZONE)) queryParams.delete(LOGIN_ZONE);
    // if (queryParams.has(REGISTER_ZONE)) queryParams.delete(REGISTER_ZONE);
    // if (queryParams.has(REGISTER_SUCCESS_ZONE)) queryParams.delete(REGISTER_SUCCESS_ZONE);
    if (queryParams.has(RESTORE_ZONE)) queryParams.delete(RESTORE_ZONE);
    if (queryParams.has(RESTORE_CONFIRM_ZONE)) {
      queryParams.delete(RESTORE_CONFIRM_ZONE);
      queryParams.delete('uid');
      queryParams.delete('code');
      queryParams.delete('token');
    }

    history.replace({ search: queryParams.toString() });
  }, [location.search, history]);

  const zoneElement = React.useMemo(() => {
    switch (zone) {
      // case RESTORE_ZONE:
      //   return (
      //     <Restore
      //       openLogin={() => setZone(LOGIN_ZONE)}
      //       openRegister={() => setZone(REGISTER_ZONE)}
      //       close={closeCallback}
      //     />);
      // case RESTORE_CONFIRM_ZONE:
      //   return (
      //     <RestoreConfirm
      //       openLogin={() => setZone(LOGIN_ZONE)}
      //       openRegister={() => setZone(REGISTER_ZONE)}
      //       close={closeCallback}
      //     />);
      // case REGISTER_ZONE:
      //   return (
      //     <Register
      //       openLogin={() => setZone(LOGIN_ZONE)}
      //       openRestore={() => setZone(RESTORE_ZONE)}
      //       openSuccess={() => setZone(REGISTER_SUCCESS_ZONE)}
      //       close={closeCallback}
      //     />);
      // case REGISTER_SUCCESS_ZONE:
      //   return (
      //     <RegisterSuccess
      //       close={closeCallback}
      //     />);
      default:
        return (
          <Login
            // openRegister={() => setZone(REGISTER_ZONE)}
            openRestore={() => setZone(RESTORE_ZONE)}
            close={closeCallback}
          />);
    }
  }, [zone, setZone, closeCallback]);

  return (
    <Grid container component={Container} maxWidth='md' alignItems='center' justifyContent='center' sx={{ height: '100dvh' }}>
      <Grid item container component={Paper} elevation={1}>
        <Grid item xs={12} md={6} sx={{ maxHeight: '100%', overflowX: 'auto' }}>
          {zoneElement}
        </Grid>
        <Grid
          item xs={0} md={6}
          sx={{
            backgroundImage: `url(${imageLogin})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            p: 4,
          }}
        >
          <Typo color='white' variant='h2'>{t('login:welcome')}</Typo>
          <Typo color='white'>{t("login:description")}</Typo>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Auth;
