// @flow

// from: https://stackoverflow.com/questions/5650924/javascript-color-contraster
export function contrastingText({ red, green, blue }) {
  let brightness;
  brightness = (red * 299) + (green * 587) + (blue * 114);
  brightness /= 255000;

  // values range from 0 to 1
  // anything greater than 0.5 should be bright enough for dark text
  if (brightness >= 0.5) {
    return "#000";
  }
  return "#fff";
}

// from: https://www.cluemediator.com/create-a-random-color-based-on-a-string-using-javascript
export function stringToRGB(str: string, ocapity: number = 0.3) {
  // get first alphabet in upper case
  const firstAlphabet = str.charAt(0).toLowerCase();

  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0);

  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

  var num = Math.round(0xffffff * parseInt(colorNum, 10));
  var red = (num >> 16) & 255;
  var green = (num >> 8) & 255;
  var blue = num & 255;

  return { red, green, blue, ocapity, text: contrastingText({ red, green, blue }) };
}

export function rgbToString({ red, green, blue, ocapity }) {
  return `rgb(${red}, ${green}, ${blue}, ${ocapity})`;
}

export function stringToColor(str: string, alpha: number = 0.3) {
  const rgb = stringToRGB(str, alpha);

  return rgbToString(rgb);
}

export default stringToColor;
